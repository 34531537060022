@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  position: absolute;
  bottom: -6px;
  left: 0;
  transform: translateY(100%);

  min-width: 227px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-white);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 25px 0;

  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: -1;
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: 10;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 28px 10px 40px;
  box-sizing: border-box;
  flex-shrink: 0;

  cursor: pointer;

  &:hover {
    background-color: var(--color-hover-bg);
  }

  & > p {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    color: var(--color-black);
  }

  &.activeItem {
    background-color: var(--color-brand);

    & > p {
      color: var(--color-white);
    }
  }

  &.disabledItem {
    pointer-events: none;

    p {
      color: var(--color-gray);
    }
  }

  img {
    margin-left: 10px;
  }
}
