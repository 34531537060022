@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.pin {
  display: flex;
  padding: 4px 8px 4px 16px;
  align-items: center;
  gap: 8px;

  max-width: 100%;

  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid var(--color-black);
  background: var(--color-white);

  flex: 0 0 auto;

  * > svg {
    flex-shrink: 0;
  }
}
