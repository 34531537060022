@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.arrow {
  border-radius: 50%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 8px 16px;
  box-sizing: border-box;
  text-align: center;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  background-color: var(--color-gray-bg-icon);
  border: 1px solid var(--color-gray-bg-icon);

  cursor: pointer;

  font-size: 0.87rem;
  color: black;
  box-shadow: none;

  &:hover {
    background-color: var(--color-middle-gray);
    border-color: #f1f2f4;
  }

  &.disabled {
    color: #879193;
    border: solid 1px var(--color-gray-bg-icon);

    cursor: auto;

    & path {
      stroke: var(--color-gray-bg-icon);
    }
  }
}

.brandColor {
  color: var(--color-brand);
  background-color: transparent;
  border: solid 1px var(--color-brand);
  &:hover {
    background-color: var(--color-gray-bg);
    border-color: var(--color-brand);
  }
}

.outline {
  background-color: transparent;
  border: solid 1px #d9dde5;
  color: #d9dde5;
  &:hover {
    background-color: var(--color-gray-bg);
    border-color: var(--color-middle-gray);
  }
}

.small24 {
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 4px 8px;
  font-size: 0.75rem;
}

.doubleArrow {
  padding: 0;
}
