@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 25px 0 35px;

  & > button > span {
    margin-left: 15px;
  }

  @include mobile {
    padding: 18px 0 20px;
    padding: 10px;

    & > button > span {
      margin-left: 0;
    }
  }
}
