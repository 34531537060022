@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 20px;
}

.pagination {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-gray-border);

  margin-right: 10px;

  &.active {
    width: 8px;
    height: 8px;
    background-color: var(--color-brand);
  }
}
