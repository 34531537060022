@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.specItem {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  & > .specTitle {
    margin-bottom: 4px;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .specValue {
    display: inline-block;
    word-break: break-word;
  }

  .copy {
    margin-left: 25px;
  }
}
