@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  align-items: center;
}

.badge {
  cursor: pointer;
  padding: 5px 25px 3px;
  margin-right: 10px;
  border-radius: 50px;
  border: 2px solid var(--color-gray-bg-icon);

  &.activeBadge {
    border-color: var(--color-black);
    color: var(--color-black);

    & > * {
      color: inherit;
    }
  }
}

.swiper {
  //   width: 242px;

  & > * {
    margin-right: 5px;
  }
}

.arrow {
  font-size: 16px;
  width: 24px;
  height: 24px;

  border: 1px solid var(--color-brand);
  color: var(--color-brand);
  margin-left: 25px;
  opacity: 0;
  pointer-events: none;

  & > * {
    font-size: inherit;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  &.prev {
    transform: rotate(180deg);
    margin-right: 25px;
    margin-left: 0;
  }
}
