@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.close-button {
  background-color: #fff;
  z-index: 2;

  svg {
    width: 20px;
    height: 20px;

    rect {
      fill: #000;
      stroke: #000;
    }
  }

  &.OUTLINE {
    background-color: transparent;
    border: 1.5px solid #fff;

    svg {
      rect {
        fill: #fff;
        stroke: #fff;
      }
    }

    &:hover {
      background-color: var(--color-gray-bg);
      border-color: var(--color-middle-gray);
    }
  }
}
