@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.block {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-bg-icon);
  background: var(--color-white);

  &.row {
    flex-direction: row;
  }
}
