@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 28px;
  letter-spacing: -0.04;
}

.value {
  font-weight: 700;
}

.divider {
  margin: 0 5px;
}

.target {
  font-weight: 400;
  color: var(--color-gray);
}

.label {
  text-transform: lowercase;
  white-space: pre-wrap;
  text-align: center;
}
