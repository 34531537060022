@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  width: 100%;
  position: relative;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}
.elements {
  display: flex;
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  &.disableEvent {
    pointer-events: none;
  }
}

.slide {
  flex: 0 0 auto;
}

.centerMode .slide {
  /* Уменьшаем ширину слайда для отображения соседних */
  width: calc(100% - 40px); /* Настройте отступ по своему усмотрению */
  margin: 0 20px;
}
