@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.spoiler {
}

.spoiler-header {
  color: var(--color-brand);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title {
  margin-right: 10px;
}

.icons {
  display: flex;
  align-items: center;
}

.additionalIcons {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 10px;
}

.icon {
  position: relative;
  top: -3px;
  width: 22px;
  height: 22px;
  font-size: 22px;
}

.spoiler-icon {
  transition: transform 0.3s;
}

.spoiler-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
}

.grayBlock {
  background-color: var(--color-gray-bg);
  padding: 30px;
  border-radius: 8px;

  @include mobile {
    padding: 25px 15px;
  }

  .grayBlockHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);

    .arrow {
      background-color: var(--color-gray-bg-icon);
      color: var(--color-black);
    }
  }

  .grayBlockContent {
    margin-top: 35px;

    @include mobile {
      margin-top: 25px;
    }

    .table {
      @include mobile {
        padding: 0;
      }
    }
  }
}
