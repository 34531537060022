@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

$mobile-horizontal-padding: 15px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  z-index: 1000;
  transition: opacity 1s ease-in-out;

  pointer-events: none;

  &.opened {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 300ms ease-in-out;

    .overlay {
      opacity: 1;
    }
  }

  &.from-bottom {
    //top: auto;
    //bottom: -100%;
    //height: 100vh;

    transform: translateY(100%);

    transition: transform 0.1s linear;

    &.opened {
      //bottom: 0;
      transform: translateY(0);
    }
  }

  .overlay {
    transition: opacity 100ms ease-in-out;

    opacity: 0;
  }
}

.overlay {
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    align-items: flex-end;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 40px 100px 70px;
  background: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;

  max-width: 75%;
  min-width: 900px;

  max-height: 95%;

  &.withFooter {
    padding-bottom: 42px;
  }

  @include mobile {
    min-width: auto;
    max-width: 100%;
    width: 100%;
    height: 97dvh;
    padding: 15px 0 35px;

    &.withFooter {
      padding-bottom: 0;
    }

    //overflow-y: auto;
  }
}

.header {
  flex: 0 0 auto;

  @include mobile {
    min-height: 25px;
    margin-left: $mobile-horizontal-padding;
    margin-right: $mobile-horizontal-padding;
  }

  .backButton {
    top: -3px;
    left: -40px;

    @include mobile {
      top: 0;
      left: 0;
    }
  }
}

.title {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -1.04px;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; /* Разрешить блоку контента растягиваться */
  position: relative;

  overflow-y: auto;

  @include mobile {
    padding: 0 $mobile-horizontal-padding;
    &.no-gutters {
      padding: 0;
    }
  }
}

.footer {
  position: relative;
  flex: 0 0 auto;

  margin-top: 70px;

  @include mobile {
    margin-top: 0;
    padding: 10px $mobile-horizontal-padding;
  }

  .gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    height: 10px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-9px);
  }
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 6px;

  z-index: 3;
  color: var(--color-white);
  border-radius: 50%;
  border: 1px solid var(--color-white);

  image {
    width: 40px;
    height: 40px;
  }

  @include mobile {
    background-color: var(--color-white);
    color: var(--color-black);
    top: 2%;
    right: 10px;
  }
}
