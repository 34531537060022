@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.pageSpace {
  @include breakpoint(1480px) {
    padding: 0 40px;
  }

  @include mobile {
    padding: 0 15px;
  }
}

.excludeMobileSpace {
  @include mobile {
    padding: 0;
  }
}
