@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50px;
  text-transform: uppercase;
  color: #9a9a9a;
}

.select {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.77px;
  margin: 0;
  text-wrap: nowrap;

  span {
    font-size: 20px;
  }

  @include mobile {
    font-size: 12px;
    line-height: 20px;
  }
}
