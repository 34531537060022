@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.box {
  padding: 32px;
  border-radius: 8px;
  background-color: var(--color-gray-f8);

  @include mobile {
    padding: 24px 16px 32px 16px;
  }

  .title {
    margin-bottom: 6px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include mobile {
      gap: 16px;
    }

    &.withTitle {
      padding: 24px 0;

      @include mobile {
        padding: 16px 0;
      }
    }
  }

  .actions {
    display: flex;
    margin-top: 6px;
  }
}
