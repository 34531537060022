@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 28px;

  margin: 30px 0 45px;
}

.optionRadio {
  display: flex;
  align-items: center;

  font-family: $font-roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  text-transform: uppercase;

  color: var(--color-gray);

  img,
  svg {
    margin-left: 30px;
  }

  &.checked {
    color: var(--color-black);
  }

  .input {
    display: none;
  }

  .fakeRadio {
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-gray);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
  }

  .input[type='radio']:checked + .fakeRadio {
    border-color: var(--color-black);
  }

  /* Внутренний круг активного состояния */
  .input[type='radio']:checked + .fakeRadio::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--color-black);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
