@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  position: relative;
}

.input {
  display: flex;
  width: 100%;
  padding: 16px 26px 12px 16px;
  border: 1px solid var(--color-gray-bg-icon);
  background-color: var(--color-white);
  box-sizing: border-box;
  border-radius: 7px;

  input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: inherit;
    line-height: inherit;
    color: var(--color-black);
    font-family: $font-roboto;

    &[type='date']::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }
  }

  .passwordToggleBtn {
    width: 32px;
    height: 32px;

    & :global #cross1,
    :global #cross2 {
      transition: stroke-dasharray, stroke-dashoffset 0.2s;

      stroke-dasharray: 23;
      stroke-dashoffset: 0;
    }

    &.passwordToggleBtnVisible {
      & :global #cross1,
      :global #cross2 {
        stroke-dashoffset: 23;
      }
    }
  }

  .calendarIcon {
    width: 20px;
    height: 24px;
    position: absolute !important;
    top: 13px !important;
    right: 26px;
    background: #fff;
    pointer-events: none;
  }

  &.password {
    padding: 8px 15px 8px 16px;
  }

  &.error {
    border-color: var(--color-red);
    color: var(--color-red);
  }

  &.disabled {
    background-color: var(--color-gray-bg);
    color: var(--color-gray);
  }
}

.maxWidth {
  max-width: 400px;

  @include mobile {
    max-width: 100%;
  }
}

.label {
  margin-bottom: 4px;
}

.errorLabel {
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);

  color: var(--color-red);

  @include mobile {
    bottom: -2px;
  }
}
