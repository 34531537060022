@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

$switch-width: 32px;
$switch-height: 16px;
$slider: 12px;
$slider-margin: 2px;
$slider-translate: 16px;

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: $slider;
  width: $slider;
  left: $slider-margin;
  bottom: $slider-margin;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--color-brand);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-brand);
}

input:checked + .slider:before {
  transform: translateX($slider-translate);
}
