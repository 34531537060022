@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  flex-grow: 0;
  align-self: flex-start;
  align-items: flex-start;
  cursor: pointer;
  @include mobile {
    width: 100%;
    justify-content: space-between;
  }

  .input {
    margin-right: 12px;

    @include mobile {
      margin-right: 0;

      margin-left: 6px;
      order: 2;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3px;

    @include mobile {
      order: 1;
    }

    .label {
      display: inline;
      color: var(--color-black);
      vertical-align: bottom;
    }

    .description {
      margin-top: 6px;
      color: var(--color-black);
    }
  }

  .checkbox {
    display: none;
  }

  .checkboxFake {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    flex-shrink: 0;

    transition: border-color 0.1s;

    .checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  .checkbox:checked + .checkboxFake {
    border-color: var(--color-black);

    .checkmark {
      opacity: 1;
    }
  }
}
