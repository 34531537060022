@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.root {
  & > div {
    & > * {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    & * {
      max-width: 100%;
    }
  }

  .wrap > *:first-child {
    line-height: 22px;
    text-transform: uppercase;

    & > * {
      &:first-child {
        text-transform: uppercase;
      }

      text-transform: none;
    }
  }

  .withHeader > *:first-child {
    line-height: 22px;
    text-transform: uppercase;

    & > * {
      &:first-child {
        text-transform: uppercase;
      }

      text-transform: none;
    }
  }

  .ol,
  .ul {
    color: var(--color-black);

    font-family: $font-roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;

    margin: 0;

    padding-left: 0;

    list-style-position: inside;
  }
}
