@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.spoilerTextBlock {
  & > *:first-child {
    margin-bottom: 5px;
    color: var(--color-gray);
  }
}
