@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  align-items: center;
  padding: 17px 56px 11px 39px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;

  @include mobile {
    padding: 12px 36px 8px 20px;
  }

  &.noPadding {
    padding: 0;
  }

  &.showValue {
    color: var(--color-black);
  }
}

.icon {
  align-self: flex-start;
  font-size: 16px;
  margin-left: 5px;
  margin-top: -3px;
  transition: transform 200ms ease-in-out;
  color: inherit;
}
