@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@import 'src/new/app/style/variables/size';

.page {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.item {
  box-sizing: border-box;
  max-width: 100%;

  &.flex {
    display: flex;
  }
}

/* Направление */
.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

/* Обёртка */
.wrap-nowrap {
  flex-wrap: nowrap;
}

.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Выравнивание по оси Y */
.align-items-flex-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

/* Выравнивание по содержанию */
.align-content-flex-start {
  align-content: flex-start;
}

.align-content-center {
  align-content: center;
}

.align-content-flex-end {
  align-content: flex-end;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

/* Выравнивание по оси X */
.justify-flex-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

/* Минимальная ширина */
.zeroMinWidth {
  min-width: 0;
}

/* Отступы (Spacing) */
$spacing-unit: 10px;

@function spacing($multiplier) {
  @return $multiplier * $spacing-unit;
}

@for $i from 1 through 10 {
  .spacing-#{$i} {
    margin: -#{spacing($i) / 2};

    > .item {
      padding: #{spacing($i) / 2};
    }
  }
}

@mixin respond-to($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if $size != 0 {
    @media (min-width: $size) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Размеры сетки */
@each $breakpoint in map-keys($breakpoints) {
  @include respond-to($breakpoint) {
    .grid-#{$breakpoint}-true {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .grid-#{$breakpoint}-auto {
      flex-basis: auto;
      flex-grow: 0;
      max-width: none;
    }

    @for $i from 1 through 12 {
      .grid-#{$breakpoint}-#{$i} {
        display: flex;
        flex-basis: ($i / 12) * 100%;
        max-width: ($i / 12) * 100%;
      }
    }
  }
}
