@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  @include mobile {
    padding: 0 15px;
  }
}

.row {
  display: flex;
  padding: 25px;

  border: 1px solid var(--color-gray-bg-icon);
  border-radius: 8px;
  background-color: var(--color-gray-bg);
  margin-bottom: 10px;

  @include mobile {
    border: none;
    border-radius: 0;
    background-color: inherit;
    padding: 15px 0;

    border-bottom: 1px solid var(--color-gray-bg-icon);

    &:last-child {
      border-bottom: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.rowLink {
  color: var(--color-black);
  text-decoration: none;

  &:hover {
    background-color: var(--color-hover-bg);
  }
}

.header {
  display: flex;
  padding: 6px 24px;

  align-items: flex-start;
  text-align: center;

  @include mobile {
    padding: 6px 0;
  }
}

.cell {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emptyLabel {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: rgba(243, 245, 246, 0.5);
  border: 1px solid var(--color-gray-bg-icon);

  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;

  line-height: 24px;

  color: var(--color-gray);
}

.divider {
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;

  position: relative;
}

.activeFilterList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
}

.selectPopup {
  transform: translateY(100%) translateX(-10%);
}
