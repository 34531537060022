@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@keyframes loading {
  0% {
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.button {
  position: relative;
  display: flex;
  padding: 13px 40px;
  justify-content: center;
  align-items: flex-end;
  border-radius: 4px;
  box-sizing: border-box;

  color: #fff;
  background-color: var(--color-brand);

  border: none;

  text-align: center;
  font-family: $font-cambay;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.66px;
  text-transform: uppercase;

  cursor: pointer;

  font-variant-numeric: lining-nums proportional-nums;

  &:disabled {
    opacity: 30%;
    pointer-events: none;
  }
}

.arrow {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: inherit;
  border-color: inherit;

  &:focus {
    background-color: inherit;
  }

  &:hover {
    background-color: var(--color-middle-gray);
    border-color: #f1f2f4;
  }
}

.outline {
  color: var(--color-brand);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  border: solid 2px var(--color-brand);
}

.plain {
  color: var(--color-brand);
  background-color: transparent;
  border: none;
  padding: 0;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--color-gray-bg-icon);

  padding: 0;

  align-items: center;
}
