@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.base {
  font-family: $font-cambay;

  &.TITLE_PAGE {
    font-family: $font-roboto;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      text-transform: none;
    }
  }

  &.HEADER {
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &.SUBHEADER {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &.BUTTON {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.66px;
    text-transform: uppercase;
    color: var(--color-gray);
  }

  &.CAMBAY_26 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: -0.26px;
  }

  &.CAMBAY_32 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
  }

  &.BIG_EMPTY_LABEL {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -0.26px;
    color: var(--color-gray);
    text-transform: uppercase;

    @include mobile {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.TEXT12 {
    font-family: $font-roboto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0.24px;
    color: var(--color-gray);
  }

  &.TEXT14 {
    font-family: $font-roboto;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -0.42px;
  }

  &.TEXT16 {
    font-family: $font-roboto;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.42px;
  }

  &.MAIN {
    font-family: $font-roboto;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.16px;
  }

  &.BIG_TEXT {
    font-family: $font-roboto;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: -0.18px;
  }

  &.LINK {
    font-family: $font-roboto;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.01px;
    text-transform: uppercase;
  }
}
