@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.text-area {
  font-family: $font-roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  padding: 15px 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  border: none;
  resize: none;

  border-bottom: rgba(0, 0, 0, 0.05) 1px solid;
  box-sizing: border-box;

  transition: border-bottom 0.3s;

  &:focus {
    outline: none;
    border-bottom: var(--color-brand) 1px solid;
  }

  &::placeholder {
    color: var(--color-gray-border);
  }
}
