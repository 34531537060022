@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.link {
  //margin: 0;
  cursor: pointer;

  display: flex;

  color: var(--color-black);
  font-family: $font-cambay;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.66px;
  text-transform: uppercase;
}

.BRAND {
  color: var(--color-brand);
}
