@import '../../../app/style/variables/colors';
@import '../../../app/style/variables/size';
@import '../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 28px;
  letter-spacing: -0.04;
}

.value {
  font-family: $font-roboto;
  font-weight: 700;

  @include mobile {
    font-size: 24px;
  }
}

.divider {
  margin: 0 5px;

  @include mobile {
    font-size: 16px;
  }
}

.target {
  font-family: $font-roboto;
  font-weight: 700;
  color: var(--color-gray);
  
  @include mobile {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
}

.label {
  text-transform: lowercase;
}
